import React,  { Component } from 'react';
import Home from './pages/Home';
import About from './pages/About';
import TermsCondition from './pages/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Disclaimer from './pages/Disclaimer';



import Login from './pages/Login';
import Register from './pages/Register';
import Protected from './protected/Protected';
import Dashboard from './pages/user/Dashboard';
import Myprofile from './pages/user/profile/Myprofile';
import ChangePassword from './pages/user/profile/ChangePassword';
import CourseList from './pages/courses/CourseList';
import CourseDetails from './pages/courses/CourseDetails';
import CourseVideo from './pages/courses/CourseVideo';



import {
  BrowserRouter,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
class App extends Component {
 
    render() {
        return (
          <BrowserRouter>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route path="/about" component={About}/>
            <Route path="/terms-and-condition" component={TermsCondition}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/disclaimer" component={Disclaimer}/>
            
            
            <Route path="/login" component={Login}/>
            <Route path="/register" component={Register}/>
            <Route path="/courses" component={CourseList}/>
            <Protected name="course" path="/course/:course_id" component={CourseDetails}/>
            <Protected name="course" path="/start-course/:course_id" component={CourseVideo}/>
            
            <Protected  path="/user/dashboard" component={Dashboard} />
            <Protected  path="/user/my-profile" component={Myprofile} />
            <Protected  path="/user/change-password" component={ChangePassword} />
            
            <Redirect to="/not-found" component={Home} />
          </Switch>
        </BrowserRouter>
          
        )
    }
}

export default App