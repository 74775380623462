import React,  { Component, Fragment } from 'react';
import circles from '../../assets/images/circles.svg';
import dot2 from '../../assets/images/dot2.svg';
import thumb from '../../assets/images/thumb.jpg';
import Header from '../common/Header';
import Footer from '../common/Footer';
import {Link} from "react-router-dom";
class Home extends Component {
  constructor(props) {
    super(props)
    this.howItWorks = React.createRef() 
}
scrollTohowItWorks = () =>  window.scroll({
  top: this.howItWorks.current.offsetTop,
  left: 0,
  behavior: 'smooth'
});
    render() {
        return (
           <Fragment>
           <Header />
               <section className="home-banner bannerH">
          <div className="backimage">
          </div>
          <div className="itm-aln">
            <div className="container">
              <div className="text-center">
                <div className="p-2 align-self-center max-with630 ">
                  <h1 className="heading-big text-center">
                    <span className="dreamGreen">Welcome</span>
                    To Prose
                  </h1>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                  <Link to="/about" className="globbal-link">Learn More</Link> <Link to="/login" className="globbal-link  white-link">Member Login</Link>
                </div>
              
                <button className="banner-down" id="you-are-btn" onClick={() => this.scrollTohowItWorks()}> 
                    <span className="down-arrow-inner">
                    </span>
                </button>
              </div>
            </div>
          </div></section>
        <section ref={this.howItWorks} id="are-you-a" className="content-section are-you">
          <div className="container">
            <div className="heading-section text-center">
              <h1 className="main-heading">
                Are You A
              </h1>
              <img src={circles} alt="" />
            </div>
            <div className="row row-flex">
              <div className="col-md-4 col-sm-6">
                <div className="card mystyleC">
                  <div className="card-thumb">
                    <img className="card-img-top" src={thumb} alt="Card  cap" />
                    <div className="overlay">
                      <div className="title-sec">
                        <h5 className="card-title">Student</h5>
                        <img src={dot2} alt="" />
                      </div> 
                    </div>
                  </div>
                  <div className="card-body text-center card-text-body">
                    <p className="card-text">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                    <Link to="/" className="card-link">Learn More </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mystyleC">
                  <div className="card-thumb">
                    <img className="card-img-top" src={thumb} alt="Card  cap" />
                    <div className="overlay">
                      <div className="title-sec">
                        <h5 className="card-title">Corp</h5>
                        <img src={dot2} alt="" />
                      </div> 
                    </div>
                  </div>
                  <div className="card-body text-center card-text-body">
                    <p className="card-text">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                    <Link to="/" className="card-link">Learn More </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mystyleC">
                  <div className="card-thumb">
                    <img className="card-img-top" src={thumb} alt="Card  cap" />
                    <div className="overlay">
                      <div className="title-sec">
                        <h5 className="card-title">Volunteer</h5>
                        <img src={dot2} alt="" />
                      </div> 
                    </div>
                  </div>
                  <div className="card-body text-center card-text-body">
                    <p className="card-text">Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                    <Link to="/" className="card-link">Learn More </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="content-section are-you">
          <div className="container">
            <div className="heading-section text-center">
              <h1 className="main-heading">
                Are You A
              </h1>
              <img src={circles} alt="" />
            </div>
            <div className="about-content">
              <p className="text-center">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet.</p>
            </div>
          </div>
        </section>
        <Footer/>
           </Fragment>
        )
    }
}

export default Home