import React,  { Component, Fragment } from 'react';
import dataLoader from '../../../assets/images/dataLoader.gif';

class Loading extends Component {
    render() {
        return (
           <Fragment>
             <img src={dataLoader} alt="data-loader"  className="form-loader"/> Please wait...
           </Fragment>
        )
    }
}

export default Loading;