import React from 'react'
import { Redirect, Route } from 'react-router-dom'
const Protected = ({component: Cmp,...rest}) => (
    <Route
    {...rest}
        render={(props)=>{
            let isAuthenticated = localStorage.getItem('isAuthenticated');
            if(isAuthenticated && isAuthenticated === 'true'){
                var userdata = JSON.parse(localStorage.getItem('userData'))
              
                if(userdata.role_id == 2){

                 return <Cmp {...props} />
                }else{
                    return <Redirect to="/login" />
                }

            }else{
                return <Redirect to="/login" />
            }
        }}
    />
)
export default Protected; 