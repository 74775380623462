import React, { Component, Fragment } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import Header from '../common/Header';
import Footer from '../common/Footer';
import Loading from './sections/Loading';

class Register extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            name:'',
            email:'',
            mobile_number:'',
            password:'',
            c_password:'',
            isAddedUser:false,
            loading: false,
            response:{},
            errorMessages:'',
        
        }
        
       this.state = this.initialState;

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

  
    handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
      }

    handleSubmit(event) {
        event.preventDefault();
        const postData = this.state;
        this.setState({loading: true});
        axios.post('http://api.articloo.com/api/register', postData)
        .then((response) => {
            if (response.data.status === 200) {
                this.setState({
                    isAddedUser:true,
                    loading: false,
                    response:response.data.message
                  });
            }

            if (response.data.status === "failed") {
                this.setState({ 
                    errorMessages: response.data.errors,
                    loading: false
                 });

                
             
            }
           
            /*  if(response.data.status == true){
                this.setState({
                    isAddedUser:true,
                    loading: false,
                    response:response.data.message
                  });
                  
            }
            //console.log(this.state.isAddedUser);  */
            //console.log(response.data);  
        })

        
             
    }
    render() {
        const { loading, errorMessages } = this.state;
       
        
        
        return (
            <Fragment>
                <Header />
                <section className="breadcum  margin-top100">
                    <div className="container">
                        <div className="text-center">
                            <h1>Register</h1>
                        </div>
                    </div>
                </section>
                <section className="main-section container section-padding ">
               
               
                {!this.state.isAddedUser === true && (
                    
                    <div className="auth-form">
                    {loading === true ? <Loading /> : null}
                        <form className="form-horizontal auth-form" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <input required="" name="name" type="text" className="form-control" placeholder="Name" value={this.state.name} onChange={this.handleChange} />
                                <span className="error">{errorMessages.name}</span>
                            </div>
                            <div className="form-group">
                                <input required="" name="email" type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleChange} />
                                <span className="error">{errorMessages.email}</span>
                            </div>
                            <div className="form-group">
                                <input required="" name="mobile_number" type="text" className="form-control" placeholder="Mobile Number" value={this.state.mobile_number} onChange={this.handleChange} />
                                <span className="error">{errorMessages.mobile_number}</span>
                            </div>
                            <div className="form-group">
                                <input required="" name="password" type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.handleChange} />
                                <span className="error">{errorMessages.password}</span>
                            </div>
                           
                            <div className="form-group">
                                <input required="" name="c_password" type="password" className="form-control" placeholder="Confirm Password" value={this.state.c_password} onChange={this.handleChange} />
                                <span className="error">{errorMessages.c_password}</span>
                            </div>
                          
                            <div className="form-group form-check">
                               <input type="checkbox" className="form-check-input" id="terms-check" required />
                               <label className="form-check-label" htmlFor="terms-check">  I agree to the Terms &  Agreement.</label>
                            </div>
                           
                            <div className="form-button">
                                <button className="btn btn-primary btn-block" type="submit">
                                Register Now
                                </button>
                            </div>
                        </form>
                    </div>
                  )
                 
                 }
                 {this.state.isAddedUser === true && (
                     <div className="responce-area">
                        <h5>{ this.state.response }</h5>
                        <Link to="/" className="btn btn-primary">Back To Home</Link>
                     </div>
                 )}
                
                </section>
                <Footer />
            </Fragment>
        )
    }
}

export default Register;