import React,  { Component, Fragment } from 'react';
import authHeader from '../../../helper/authHeader';

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
          error: null,
          user: []
        }
      }
      componentDidMount() {
        const apiUrl = 'http://api.articloo.com/api/user/my-profile';

        fetch(apiUrl,{
            headers: authHeader()
          })
          .then(res => res.json())
          .then(
            (result) => {
              this.setState({
                user: result
              });
            },
            (error) => {
              this.setState({ error });
            }
          )
      }
    render() {
        const user = this.state.user;
        return (

           <Fragment>
             <section className="container" >
             <div className="profile-box">
           
             <div className="card">
              <div className="card-header">
              <h5 className="card-title">Profile</h5>
              </div>
             <div className="card-body">
             <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>Full Name:</td>
                                            <td>{ user.name }</td>
                                        </tr>
                                        <tr>
                                            <td>Email:</td>
                                            <td>{ user.email }</td>
                                        </tr>
                                        <tr>
                                            <td>Mobile Number:</td>
                                            <td>{ user.mobile_number }</td>
                                        </tr>
                                        <tr>
                                            <td>City:</td>
                                            <td>{ user.city }</td>
                                        </tr>
                                        <tr>
                                            <td>State:</td>
                                            <td>{ user.state }</td>
                                        </tr>
                                        <tr>
                                            <td>Country:</td>
                                            <td>{ user.country }</td>
                                        </tr>
                                        <tr>
                                            <td>Zipcode:</td>
                                            <td>{ user.zipcode }</td>
                                        </tr>
                                        <tr>
                                            <td>is_Verified:</td>
                                            <td>{ user.is_verified === 1? "Verified User" : "Non Verified User" }</td>
                                        </tr>

                                    </tbody>
                                </table>
                                <button className="btn btn-info" onClick={()=>this.props.onEdit(user.id)}>Edit Profile</button>
                               
             </div>
            </div>
            
                              
             </div>
           
           
                               
                                
             </section>
           
          </Fragment>
        )
    }
}

export default Profile;