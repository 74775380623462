import React, { Fragment } from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import authHeader from '../../helper/authHeader';
import FullpageLoader from '../sections/FullpageLoader';
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';
import {Link} from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import { Twitter, Facebook } from 'react-social-sharing';
import useSWR from 'swr'

const fetcher = url => fetch(url, {
  headers: authHeader()
}).then(r => r.json())
  
function CourseDetails(props){
  let id = props.match.params.course_id;
  var course = '';
  var total_review;
  const apiUrl = 'http://api.articloo.com/api/course-details/' + id;
  const { data } = useSWR(apiUrl,fetcher);
  if(data){
    course =  data.courseDetails;
    total_review = data.total_review;
   }
 


   
   
   
        return (
           <Fragment>
            <Header />
            {
              course !== '' ? (
                <div>
                <section className="breadcum  margin-top100 pdbt0">
                <div className="container">
                <div className="row">
                    <div className="col-md-8">
                     <h1>{course.title}</h1>
                     <p className="text-white">{course.excerpt}</p>
                     <ReactStars
                      count={5}
                      size={24}
                      value={total_review}
                      activeColor="#ffd700"
                     />
                     <Twitter link="http://localhost:3000/course/finding-money-to-study-europe" />
                     <Facebook link="http://localhost:3000/course/finding-money-to-study-europe" />

  

                    </div>
                    <div className="col-md-4 mgbot0">
                    <div className='player-wrapper'>
                      <ReactPlayer
                       className='react-player'
                       url={course.intro_video}
                       light ={course.small_thumb}
                       width='100%'
                       height='100%'
                       controls = {true}
                       />
                       <div className="preview_text">
                         <h3>Preview this course</h3>
                         <Link to={{pathname: `/start-course/${course.course_slug}`}} className="globbal-link btn-block text-center">View More</Link>
                       </div>
                      
                      </div>
                    </div>
                </div>
               
                
                
                </div>   
               </section> 
               <section className="main-section container section-padding-sm" >
                  <div className="row">
                    <div className="col-md-8">
                    <img className="card-img-top" src={ course.big_thumb } alt="Card  cap" />
                    <div className="course-description">
                          {parse(course.description)}  
                       </div>
                      
                    </div>
                    <div className="col-md-4">
                    <div className="card">
                      <div className="card-header">
                          <h5>Course Review</h5> 
                      </div>
                      <div className="card-body">
                         {parse(course.reviews_sec)}
                      </div>
                      </div>
                    </div>
                  </div>
               </section>
                </div>
              ):<FullpageLoader />
            }
            <Footer/>
           </Fragment>
        )
    
}

export default CourseDetails;