import React, { Component ,Fragment} from 'react'
export class EditProfile extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            id:'',
            name: '',
            email: '',
            mobile_number:'',
            city:'',
            state:'',
            country:'',
            zipcode:'',
            alert_message: ''
        };
        if(props.user){
            this.state = props.user
        }else{
            this.state = this.initialState;
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
    handleSubmit(event) {
        event.preventDefault();
        this.props.onFormSubmit(this.state);
        this.setState(this.initialState);
    }



    render() {
        return (

            <Fragment>
              <div className="profile-box">
              <div className="card">
              <div className="card-header">
              <h5 class="card-title">Update Profile</h5>
              </div>
              <div className="card-body">
               <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input name="id" type="hidden" className="form-control" placeholder="User id" value={ this.state.id } />
                                </div>
                                <div className="form-group">
                                    <input name="name" type="text" className="form-control" placeholder="Name" value={this.state.name } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="email" type="email" className="form-control" placeholder="Email" value={ this.state.email } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="mobile_number" type="text" className="form-control" placeholder="Mobile Number" value={ this.state.mobile_number } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="city" type="text" className="form-control" placeholder="City" value={ this.state.city } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="state" type="text" className="form-control" placeholder="State" value={ this.state.state } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="country" type="text" className="form-control" placeholder="Country" value={ this.state.country } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="zipcode" type="text" className="form-control" placeholder="Zipcode" value={ this.state.zipcode } onChange={this.handleChange}/>
                                </div>

                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit">Update Now</button>
                                </div>
                            </form>
                       </div>
                       </div>
                       </div>
            </Fragment>

        )
    }
}

export default EditProfile;
