import React, { Component ,Fragment} from 'react'
import authHeader from '../../../helper/authHeader';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import axios from 'axios';
export class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
            current_password: '',
            new_password: '',
            confirm_new_password:'',
            responce:''
        };
        this.state = this.initialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
      handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        });
    }
   
    handleSubmit(e){
        e.preventDefault();
        const apiUrl = 'http://api.articloo.com/api/user/update/password';
        const postData = this.state;
       axios.post(apiUrl, postData, {
        headers: authHeader()
       })
       .then(result => {
        this.setState({
            responce:result.data
          });
      
       })
       .catch(error => {
         this.setState({
           errors: error
         })
       })
      }


    render() {
        return (

            <Fragment>
            <Header/>
            <section className="breadcum  margin-top100">
             <div className="container text-center">
                  <h1>Change Password</h1>
             </div>   
             </section> 
             <section className="main-section container section-padding" >
              <div className="profile-box">
              {
                this.state.responce.status === 'success' && (
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                          <strong>Successfull !</strong>   { this.state.responce.message }
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )
              }
             
              {
               
                 this.state.responce.status === 'error' && (
                        <div className="alert alert-danger alert-dismissible fade show" role="alert">
                          <strong>Opps !</strong>   { this.state.responce.message }
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )
             }
              <div className="card">
              <div className="card-header">
              <h5 class="card-title">Change Password</h5>
              </div>
              <div className="card-body">
               <form className="form-horizontal" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input name="current_password" type="password" className="form-control" placeholder="Current Password" value={this.state.current_password } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="new_password" type="password" className="form-control" placeholder="New Password" value={ this.state.new_password } onChange={this.handleChange}/>
                                </div>
                                <div className="form-group">
                                    <input name="confirm_new_password" type="password" className="form-control" placeholder="Confirm New Password" value={ this.state.confirm_new_password } onChange={this.handleChange}/>
                                </div>
                                <div className="form-button">
                                    <button className="btn btn-primary" type="submit">Update Now</button>
                                </div>
                            </form>
                       </div>
                       </div>
                       </div>
                       </section>
                       <Footer/>
            </Fragment>

        )
    }
}

export default ChangePassword;
