import React,  { Component, Fragment } from 'react';
import axios from 'axios';
import logo from '../../assets/images/logo.png';
import {Link, withRouter} from "react-router-dom";
import { User } from 'react-feather';
class Header extends Component {
  handleLogout = () => {
    this.revokeAccessToken();
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userData');
    return this.props.history.push('/');
};

revokeAccessToken = () => {
    axios.get('http://127.0.0.1:8000/api/user/logout', {}).then((response) => {
       console.log(response.data);
    
    }).catch((error) => {
      console.log(error);
    });
};//..... end of revokeAccessToken() .....//

    render() {
        const isAuthenticated = localStorage.getItem('isAuthenticated');
        return (
           <Fragment>
               <header id="navbar">
              
          <nav className="navbar navbar-expand-md navbar-dark fixed-top" id="header">
            <div className="container">
              {/* Brand */}
              
              <Link className="navbar-brand" to="/"><img src={logo} alt="prose" /></Link>
              {/* Navbar links */}
              <div className="collapse navbar-collapse">
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item active">
                    <Link className="nav-link" to="/about">About</Link>
                    <span className="menu-dot" />
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/">Student</Link>
                    <span className="menu-dot" />
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/courses">Courses</Link>
                    <span className="menu-dot" />
                  </li>
                  <li className="nav-item dropdown">
                    <Link to="/" className="nav-link dropdown-toggle" data-toggle="dropdown">
                      More
                    </Link>
                    <span className="menu-dot" />
                    <div className="dropdown-menu">
                      <Link className="dropdown-item" to="/">Link 1</Link>
                      <Link className="dropdown-item" to="/">Link 2</Link>
                      <Link className="dropdown-item" to="/">Link 3</Link>
                      <Link className="dropdown-item" to="/">Link 4</Link>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="auth-btn-section">
              {isAuthenticated && isAuthenticated === 'true' && (
                <>
                 <div className="dropdown">
        <button className="auth-btn dropdown-toggle caret-hide" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <User />
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
          <Link className="dropdown-item" to="/user/dashboard">Dashboard</Link>
          <Link className="dropdown-item" to="/user/my-profile">My Profile</Link>
          <Link className="dropdown-item" to="/user/change-password">Change Password</Link> 
          <a href="/" className="dropdown-item" onClick={this.handleLogout}>Logout</a>
         
        </div>
      </div>
                </>
             )}
             {!isAuthenticated && (
                <>
                <Link to="/login" className="auth-btn">Login</Link>
                <Link to="/register" className="auth-btn">Register</Link>
                </>
            )}
                {/* Toggler/collapsibe Button */}
                <button className="menu"><span /><span /><span /></button>
              </div>
            </div>
          </nav>
        </header>
           </Fragment>
        )
    }
}

export default withRouter(Header);