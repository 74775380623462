import React,  { Component, Fragment } from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
class Dashboard extends Component {
   
    render() {
        return (

           <Fragment>
            <Header/>
            <section className="breadcum  margin-top100">
             <div className="container">
              <div className="d-flex">
                <div className="p-2 flex-grow-1">
                  <h1>User Dashboard</h1>
                </div>
              </div>
             </div>   
             </section> 
             <section className="main-section container section-padding" >
           

           
             </section>
            <Footer/>
          </Fragment>
        )
    }
}

export default Dashboard;