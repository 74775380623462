import React,  { Component, Fragment } from 'react';
import authHeader from '../../../helper/authHeader';
import Header from '../../../common/Header';
import Footer from '../../../common/Footer';
import Profile from './Profile';
import EditProfile from './EditProfile';
import axios from 'axios';
class Myprofile extends Component {
  constructor(props){
    super(props);
    this.state = {
        user: {},
        isEditProfile:false,
        error:null,
        responce:{}
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);
}
  onEdit = user_id =>{
    const apiUrl = 'http://api.articloo.com/api/user/get-user';
    const formData = new FormData();
    formData.append('user_id',user_id);
    const options = {
        method: 'POST',
        body: formData,
        headers: authHeader()
    }
    fetch(apiUrl, options)
   .then(res => res.json())
   .then(
     (result) => {
       this.setState({
         user: result,
         isEditProfile:true
       });
     },
     (error) => {
       this.setState({ error });
     }
   )
 }

 onFormSubmit(data) {
  const apiUrl = 'http://api.articloo.com/api/user/update/account';
 axios.post(apiUrl, data, {
  headers: authHeader()
 })
 .then(result => {
  this.setState({
      responce:result.data,
      isEditProfile:false
    });
  
 })
 .catch(error => {
   this.setState({
     errors: error.response.data.errors
   })
 })
}
    render() {
      console.log(this.state.responce);
        return (

           <Fragment>
            <Header/>
            <section className="breadcum  margin-top100">
             <div className="container text-center">
                  <h1>My Profile</h1>
             </div>   
             </section> 
             <section className="main-section container section-padding" >
             <div className="profile-box">
             {
                this.state.responce.message && (
                        <div className="alert alert-success alert-dismissible fade show" role="alert">
                          <strong>Successfull !</strong>   { this.state.responce.message }
                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                      )
             }
             </div>
             { !this.state.isEditProfile && <Profile onEdit={this.onEdit}/>}
             { this.state.isEditProfile && <EditProfile user={this.state.user} onFormSubmit={this.onFormSubmit}/>}
            
             </section>
            <Footer/>
          </Fragment>
        )
    }
}

export default Myprofile;