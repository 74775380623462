import React,  { Component, Fragment } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";
import Header from '../common/Header';
import Footer from '../common/Footer';
import Loading from './sections/Loading';
import { Redirect } from 'react-router-dom';
class Login extends Component {
    constructor(props) {
        super(props);
        this.initialState = {
          email:'',
          password:'',
          loading: false,
          respAlert:'',
          validationError:'',
      }
      this.state = this.initialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
      }
    
      handleChange(e) {
        this.setState({ [e.target.name]: e.target.value });
      }
   
      handleSubmit(e) {
        e.preventDefault();
        this.setState({loading: true});
        axios.post('http://api.articloo.com/api/login', {
          email: this.state.email,
          password: this.state.password
      })
      .then((response) => {   
        if (response.data.status === true) {
            this.setState({
              respAlert:response.data.message,
              loading: false
            });
            this.persistUserDetails(response.data);
            
      }

      if (response.data.status === "failed") {
          this.setState({ 
              validationError: response.data.errors,
              loading: false
           }); 
      }

      if (response.data.status === "unautherized") {
        this.setState({ 
            respAlert: response.data.message,
            loading: false
         }); 
    }


      })
      .catch((error) => {
          this.setState({respAlert:error});
          console.log(error);
      });
       
      }



      persistUserDetails = (data) => {
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userData', JSON.stringify(data));
        this.isLoggedIn();
    };//..... end of persistUserDetails() .....//
    isLoggedIn = () => {
        let isAuthenticated = localStorage.getItem('isAuthenticated');
          if (isAuthenticated && isAuthenticated === 'true') {
            const userdata = JSON.parse(localStorage.getItem('userData'))

            if(userdata.role_id === 2){
                var from = '/user/dashboard';
            }

             if (this.props.location.state && this.props.location.state.from)
                 from = this.props.location.state.from;
             return this.props.history.push(from);
         }//..... end if() ....//

     };//..... end of isLoggedIn() .....//
    render() {
      const isAuthenticated = localStorage.getItem('isAuthenticated');
      if(isAuthenticated && isAuthenticated === 'true'){
          return <Redirect to="/" />
         }
      const { loading, validationError, respAlert } = this.state;
        return (
           <Fragment>
           <Header />
           <section className="breadcum  margin-top100">
             <div className="container">
                <div className="text-center">
                <h1>Login</h1>
              </div>
             </div>   
             </section> 
               <section className="container section-padding full-height align-item">
               <div className="auth-form">
               {loading === true ? <Loading /> : null}
               <p className="error big-alert">{ respAlert }</p>
               <form className="form-horizontal auth-form" onSubmit={this.handleSubmit}>
                                <div className="form-group">
                                    <input required="" name="email" type="email" className="form-control" placeholder="Email" value={this.state.email} onChange={this.handleChange}/>
                                    <span className="error">{validationError.email}</span>
                                </div>
                                <div className="form-group">
                                    <input required="" name="password" type="password" className="form-control" placeholder="Password" value={this.state.password} onChange={this.handleChange}/>
                                    <span className="error">{validationError.password}</span> 
                               </div>
                                <div className="form-group">  
                                    <div className="d-flex">
                                    <div className="p-2 flex-grow-1">
                                      <Link to="/" className="btn btn-default forgot-pass p-0">Forget Password</Link>
                                    </div>
                                      
                                    <div className="p-2">
                                      <Link to="/register" className="btn btn-default forgot-pass p-0">Register</Link>
                                    </div>
                                    </div>
                                </div>
                                <div className="form-button">
                                    <button className="btn btn-primary btn-block" type="submit">Login</button>
                                </div>
                            </form>
               </div>
                </section>
            <Footer/>
           </Fragment>
        )
    }
}

export default Login;