import React, { Component, Fragment } from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import authHeader from '../../helper/authHeader';
import FullpageLoader from '../sections/FullpageLoader';
import ReactPlayer from 'react-player';
import ReactStars from "react-rating-stars-component";
import axios from 'axios';
class CourseVideo extends Component {
  constructor() {
    super();
    this.state = {
      course: null,
      videoSrc: '',
      videos: {},
      totalDurationVid: '',
      validationMsg:'', 
      ratting:'',
      reviews:null,
      is_reviews:false,
      total_approve_rev:''
    };
    this.handleChangeheadline = this.handleChangeheadline.bind(this);
    this.handleChangereview = this.handleChangereview.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    let id = this.props.match.params.course_id;
    const apiUrl = 'http://api.articloo.com/api/course-video/' + id;
    fetch(apiUrl, { headers: authHeader() })
      .then(res => res.json())
      .then(
        (result) => {
          this.setState({
            course: result.courseDetails,
            videos: result.courseDetails.upload_videos,
            videoSrc: result.courseDetails.intro_video,
            totalDurationVid: result.totalVidDur,
            reviews:result.courseDetails.reviews,
            total_approve_rev:result.total_review,
          });
          console.log(result);
        },
        (error) => {
          this.setState({ error });
        }
      )
  }
  onPlay = (video_url) => {
    this.setState({
      videoSrc: video_url
    });
  }

  handleChangeheadline(e) {
    this.setState({headline: e.target.value});
   
   }
   handleChangereview(e) {
    this.setState({review: e.target.value});
   
   }
   handleSubmit(event) {
    event.preventDefault();
     axios.post('http://api.articloo.com/api/post-review', {
       ratting : this.state.ratting,
       headline : this.state.headline,
       review : this.state.review,
       uid: this.uidInput.value,
       courseId: this.actionCourseid.value
  },{headers: authHeader()})
  .then((response) => {   
    if (response.data.status === "failed") {
      this.setState({ 
        validationMsg: response.data.errors,
        is_reviews:false
       });
    }
    if (response.data.status === "success") {
      let id = this.props.match.params.course_id;
      const apiUrl = 'http://api.articloo.com/api/course-video/' + id;
      fetch(apiUrl, { headers: authHeader() })
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({
              reviews:result.courseDetails.reviews,
              is_reviews:true
            });
          },
          (error) => {
            this.setState({ error });
          }
        )
    }
   
  })
  .catch((error) => {
      this.setState({respAlert:error});
      console.log(error);
  });

  }

  render() {
    const course = this.state.course;
    const videoSrc = this.state.videoSrc;
    const validationMsg = this.state.validationMsg;
    const reviews = this.state.reviews;
    const is_reviews = this.state.is_reviews;
    const total_approve_rev = this.state.total_approve_rev;
    
    
    
    const reanderVideo = () => {
      return (
        <ReactPlayer
          className='react-player'
          url={videoSrc}
          width='100%'
          height='100%'
          controls={true}
        />
      )
    }
    const ratingChanged = (newRating) => {
      this.setState({
        ratting: newRating
    });
    };


    const isAuthenticated = localStorage.getItem('isAuthenticated');
    if(isAuthenticated && isAuthenticated === 'true'){
    var userdata = JSON.parse(localStorage.getItem('userData'))
   }
    return (

      <Fragment>

        {!course && course === null && (
          <FullpageLoader />
        )
        }
        {course && course != null && (<>
          <Header />
          <section className="breadcum  margin-top100">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <h1>{course.title}</h1>
                  <p className="text-white">{course.excerpt}</p>
                  <ReactStars
                      count={5}
                      size={24}
                      value={total_approve_rev}
                      activeColor="#ffd700"
                     />
                </div>

              </div>

            </div>
          </section>
          <section className="main-section container section-padding-sm" >
            <div className="row">
              <div className="col-md-9">
                {reanderVideo()}

              </div>
              <div className="col-md-3">
                <div className="card course-content">
                  <div className="card-header courses-title">
                    <h5> Finding Money To Study Abroad</h5>
                    <p>Course Content</p>
                    <div className="play_status">
                      <i className="fa fa-play-circle" aria-hidden="true" /> {this.state.totalDurationVid} Min
          </div>
                  </div>
                  <div className="card-body pd0">
                    <ul className="course-list-item">
                      {
                        this.state.videos.length === 0
                          ? (<>
                            <h5 className="video-not-found">Video Not Found !</h5>
                          </>)
                          : this.state.videos.map(video => (
                            <li key={video.id}> <a href="#!" className="video-btnd" onClick={() => this.onPlay(video.video_name)}>{video.video_title}
                              <div className="play_status">
                                <i className="fa fa-play-circle" aria-hidden="true" /> {video.video_duration} Min
                </div>
                            </a>
                            </li>
                          ))
                      }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header">
                    <h5>{ reviews.length === 0 ? 'Leave a Ratting' : 'Your Ratting' }</h5>
                  </div>
                
                  <div className="card-body">
                  { reviews.length === 0 && is_reviews == false ? (   
        <>        
                  <div className="form-group">
         <ReactStars
           count={5}
           onChange={ratingChanged}
           size={24}
           activeColor="#ffd700"
          />
           <span className="error">{validationMsg.ratting}</span>
       </div>
                    <form onSubmit={this.handleSubmit}>
                      <input type="hidden" name="uid" value={userdata.id} ref={(input) => { this.uidInput = input }} />
                      <input type="hidden" name="courseid" value={course.id} ref={(input) => { this.actionCourseid = input }} />
                      <div className="form-group">
                        <label>Headline</label>
                        <input name="headline" type="text" className="form-control" value={this.state.headline} onChange={this.handleChangeheadline} />
                        <span className="error">{validationMsg.headline}</span>
                      </div>
                      <div className="form-group">
                        <label>Review</label>
                        <textarea name="review" className="form-control" value={this.state.review} onChange={this.handleChangereview}></textarea>
                        <span className="error">{validationMsg.review}</span>
                      </div>
                      <button type="submit" className="globbal-link">Submit</button>
                    </form>
                    </>
                  ):

                    reviews.map(review => (
        <>
        {
          userdata.id == review.user_id && (
            <div className="review-box" key={review.id}>
            <ReactStars
           count={5}
           size={24}
           value={review.ratting}
           activeColor="#ffd700"
          />
        <p>{review.headline}</p>
        <p>{review.description}</p>
       
        </div>
          )
        }
        
        </>
      ))
      }
                  </div>
                </div>
              </div>
            </div>

          </section>

          <Footer />
        </>
        )
        }
      </Fragment>
    )
  }
}

export default CourseVideo;