import React,  { Component, Fragment } from 'react';
import dataLoader from '../../../assets/images/dataLoader.gif';

class FullpageLoader extends Component {
    render() {
        return (
           <Fragment>
           <div className="loader-wrapper">
              <img src={dataLoader} alt="data-loader"/> 
           </div>    
           </Fragment>
        )
    }
}

export default FullpageLoader;