function authHeader() {
    // return authorization header with basic auth credentials
    let isAuthenticated = localStorage.getItem('isAuthenticated');
    if(isAuthenticated && isAuthenticated === 'true'){
        const user = JSON.parse(localStorage.getItem('userData'))
        if (user && user.access_token) {
         return { Authorization: `Bearer ${user.access_token}` };
        }
    } else {
        return {};
    }
}
export default authHeader;
