import React,  { Component, Fragment } from 'react';
import logoW from '../../assets/images/logoW.png';
import {Link} from "react-router-dom";
class Footer extends Component {
    render() {
        return (
           <Fragment>
               <section className="subfooter">
          <div className="container">
            <div className="row text-left">
              <div className="col-md-4 mb-center">
                <div className="logo-col">
                  <img src={logoW} alt="" />
                </div>
              </div>
              <div className="col-md-4 mb-center">
                <div className="usefull-link">
                  <Link to="/terms-and-condition">
                    Terms &amp; Condition
                  </Link>
                  <Link to="/privacy-policy">
                    Privacy Policy
                  </Link>
                  <Link to="/disclaimer">
                    Disclaimer
                  </Link>
                </div>
              </div>
              <div className="col-md-4 mb-center">
                <div className="footer-about">
                  <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.</p>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="p-2 flex-grow-1 mb-hide">
                <hr />
              </div>
              <div className="p-2 footer-mb-center">
                <div className="social">
                  <Link className="socil-media" to="/"><i className="fa fa-twitter" /></Link>
                  <Link className="socil-media" to="/"><i className="fa fa-linkedin" /></Link>
                  <Link className="socil-media" to="/"><i className="fa fa-facebook" /></Link>
                  <Link className="socil-media" to="/"><i className="fa fa-youtube-play" /></Link>
                  <Link className="socil-media" to="/"><i className="fa fa-instagram" /></Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <footer>
          <div className=" footerCon">
            <div className="container">
              <div className="text-left">
                © Copyright 2015 - 2020. All Rights Reserved By <a href="https://acrossthehorizon.net">Across The Horizon</a>
              </div>
            </div>
          </div>
        </footer>
           </Fragment>
        )
    }
}

export default Footer
