import React from 'react';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Link} from "react-router-dom";
import FullpageLoader from '../sections/FullpageLoader';
import useSWR, { SWRConfig } from 'swr'
 function CourseList(){
  const apiUrl = 'http://api.articloo.com/api/courses';
  const { data } = useSWR(apiUrl);
  //console.log(pageContent.title);
 
  return (
    <div>
      <SWRConfig
      value={{
        refreshInterval: 3000,
        fetcher: (...args) => fetch(...args).then(res => res.json())
      }}
    >
     <Header />
     <section className="breadcum  margin-top100">
             <div className="container text-center">
                  <h1>Our Courses</h1>
             </div>   
             </section> 
     <section className="main-section container section-padding" >
          <div className="container">
     <div className="row">
       {
         data ? (
         data.map(course => (
          <div className="col-md-4 col-sm-6" key={course.id}>
                <div className="card mystyleC">
                  <div className="card-thumb">
                    <img className="card-img-top" src={ course.small_thumb } alt="Card  cap" />
                  </div>
                  <div className="card-body text-left card-text-body">
                     <div className="course-title">
                        <h5>{ course.title }</h5>
                      </div> 
                    <p className="card-text">{ course.excerpt }</p>
                    <Link to={{pathname: `/course/${course.course_slug}`}} className="card-link">Learn More</Link>
                  </div>
                </div>
              </div>
         ))

         ):<FullpageLoader/>
       }
</div>
</div>
</section>

     <Footer />
    </SWRConfig>
     
    </div>
  );
}
export default CourseList;