import React,  { Fragment } from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
import FullpageLoader from './sections/FullpageLoader';
import parse from 'html-react-parser';
import useSWR, { SWRConfig } from 'swr'
 function PrivacyPolicy(){
  const apiUrl = 'http://api.articloo.com/api/privacy-policy';
  const { data: pageContent } = useSWR(apiUrl);
  //console.log(pageContent.title);
 
  return (
    <div>
      <SWRConfig
      value={{
        refreshInterval: 3000,
        fetcher: (...args) => fetch(...args).then(res => res.json())
      }}
    >
     <Header />
     { pageContent? (
       <Fragment>
     <section className="breadcum  margin-top100">
             <div className="container">
              <div className="d-flex">
                <div className="p-2 flex-grow-1">
                <h1>{ pageContent.title }</h1>
                </div>
                <div className="p-2">
                 
                </div>
              </div>
             </div>   
             </section> 
               <section className="main-section container section-padding" id="about-us" >
                 {parse(pageContent.description)}  
                </section>
                </Fragment>
     ):<FullpageLoader/>}

     <Footer />
    </SWRConfig>
     
    </div>
  );
}
export default PrivacyPolicy;